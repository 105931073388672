<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        rounded
        text
        small
        :fab="$vuetify.breakpoint.xsOnly"
        v-on="on"
        color="blue-grey"
        dark
      >
        <span v-if="!$vuetify.breakpoint.xsOnly">Email</span>
        <v-icon :right="!$vuetify.breakpoint.xsOnly" small dark
          >mdi-email</v-icon
        >
      </v-btn>
    </template>

    <v-card>
      <ModelTitle title="Send Mail" @close="dialog = false" />

      <v-container grid-list-lg>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                :error-messages="emailErrors"
                :hide-details="emailErrors.length === 0" 
                v-model="Email"
                @focus="$event.target.select()"
                label="Email"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                :error-messages="ccErrors"
                :hide-details="ccErrors.length === 0" 
                v-model="cc"
                @focus="$event.target.select()"
                label="Cc"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                :error-messages="subjectErrors"
                :hide-details="subjectErrors.length === 0" 
                v-model="subject"
                @focus="$event.target.select()"
                label="Subject"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text>
          <div v-html="viewHtml"></div>
        </v-card-text>
      </v-container>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="mail()"> Send </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import customerService from "@/modules/Customer/service";
import Hashids from "hashids";
const hashids = new Hashids();
const { validationMixin } = require("vuelidate");
const { required, minLength, email } = require("vuelidate/lib/validators");

export default {
  name: "send-email",
  props: ["email", "type", "id", "name", "postfix", "obj"],
  data() {
    return {
      dialog: false,
      subject: null,
      viewHtml: null,
      url: null,
      Email: null,
      cc: null,
    };
  },
  computed: {
    subjectErrors() {
      const errors = [];
      if (!this.$v.subject.$dirty) return errors;
      if (!this.$v.subject.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.subject.minLength)
        errors.push("Subject must be at least 10 characters long");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.Email.$dirty) return errors;
      if (!this.$v.Email.email)
        errors.push("Email is not valid");
      if (!this.$v.Email.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      return errors;
    },
    ccErrors() {
      const errors = [];
      if (!this.$v.cc.$dirty) return errors;
      if (!this.$v.cc.email)
        errors.push("cc is not valid");
      return errors;
    },
  },
  mixins: [validationMixin],
  validations: {
    subject: {
      required,
      minLength: minLength(10),
    },
    Email: {
      email,
      required,
    },
    cc: {
      email,
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.Email = this.email
        this.subject = `Here is your ${this.type}: ${this.id} from ${this.name}`;
        const hashId = hashids.encode(this.id);

        this.url = `${process.env.VUE_APP_FE_URL}/public/${this.type}/${hashId}/${this.postfix}`;

        this.viewHtml = `<html><p>hey, ${this.obj.name}<p>
              <p>Please click link blow ro view your ${this.type}: ${this.id}.</p>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td>
                    <table border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td>
                          <a href="${this.url}" target="_blank" style="font-size: 16px; font-family: Helvetica, Arial, sans-serif; color: #ffffff; text-decoration: none; border-radius: 3px; background-color: #EB7035; border-top: 12px solid #EB7035; border-bottom: 12px solid #EB7035; border-right: 18px solid #EB7035; border-left: 18px solid #EB7035; display: inline-block;">View ${this.type}</a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
              <p style="margin-top: 10px;">if you can't click on above button, try click on like blow to try copy and pasting on browser</p>
                          
              <p><a href="${this.url}" target="_blank">${this.url}</a></p>
              <p style="margin-top:20px;">Thanks for your business.</p>
              <span style="font-size: 18px;">${this.name}</span>
              <div style="font-size: 12px;">Powered By <a href='https://smplsale.com'>smplsale.com</a></div>
              
              </html>`;
      }
    },
  },
  methods: {
    async mail() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        let data = {
          to: this.Email,
          subject: this.subject,
          html: this.viewHtml,
        };
        if (this.cc) {
          data.cc = this.cc
        }
        // // console.log('obj', obj)
        await customerService.sendEmail(data).then((response) => {
          // console.log('response sendEmail', response)
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            .fire({
              icon: "success",
              title: `Email was send successfully to ${this.email}`,
            });
        });

        this.dialog = false;
      }
    },
  },
};
</script>

<style scoped></style>
