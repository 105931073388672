<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else-if="linkExpired">
      <LinkExpiredPage />
    </div>
    <div v-else>
      <v-card flat v-if="currentBusiness">
        <PrintViewBar v-if="Invoice" :showEmailBtn="showEmailBtn" :id="Invoice.id" type="invoice"
          :title="`Invoice # ${this.Invoice.localId}`" :postfix="`view?key=${currentBusiness.code}`"
          :name="currentBusiness.name" :email="Invoice.Customer.email" :obj="Invoice.Customer"
          :text="`Invoice from ${currentBusiness.name} click the link to view`" />
        <!-- currentBusiness: {{ currentBusiness.name }} -->
        <v-container fluid grid-list-lg>
          <v-card-text class="pb-0">

            <div v-if="!$vuetify.breakpoint.xsOnly">
              <img class="paidImg" v-if="Invoice.due === 0" width="200" src="../../../assets/paidStamp.png" />
              <img class="paidImg" v-if="Invoice.deletedAt" width="200"
                src="../../../assets/deletedStamp-removebg-preview.png" />
            </div>

            <v-layout row wrap>
              <v-flex class="text-left text-capitalize">
                <div class="headline font-weight-bold">
                  {{ currentBusiness.name }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.address }} {{ currentBusiness.city }},
                  <span class="text-uppercase">{{ currentBusiness.state }}
                  </span>
                  {{ currentBusiness.zip }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.phoneNumber }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.email }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.website }}
                </div>

                <div class="mt-2 display-3 font-weight-black blue--text">
                  INVOICE
                </div>
                <v-layout row wrap>
                  <v-flex>
                    <div class="font-weight-regular">
                      <div class="font-weight-medium">BILL TO</div>
                      <div class="font-weight-bold">
                        {{ Invoice.Customer.name }}
                      </div>
                      <div>{{ Invoice.Customer.address }}</div>
                      <div v-if="Invoice.Customer.city">
                        {{ Invoice.Customer.city }},<span class="text-uppercase">{{ Invoice.Customer.state }}
                        </span>
                        {{ Invoice.Customer.zip }}
                      </div>
                    </div>
                  </v-flex>
                  <v-flex v-if="Invoice.Customer.shippingAddress">
                    <div class="font-weight-regular">
                      <div class="font-weight-medium">SHIP TO</div>
                      <div class="font-weight-bold">
                        {{ Invoice.Customer.name }}
                      </div>
                      <div>{{ Invoice.Customer.shippingAddress }}</div>
                      <div v-if="Invoice.Customer.shippingCity">
                        {{ Invoice.Customer.shippingCity }},<span class="text-uppercase">{{
                          Invoice.Customer.shippingState }}
                        </span>
                        {{ Invoice.Customer.shippingZip }}
                      </div>
                    </div>
                  </v-flex>
                </v-layout>


              </v-flex>
              <v-spacer></v-spacer>
              <v-flex shrink class="text-right">
                <div v-if="!$vuetify.breakpoint.xsOnly">
                  <img v-if="currentBusiness.imageUrl" width="130" class="logo mb-3" :src="currentBusiness.imageUrl"
                    alt="" />
                </div>
                <div v-if="!$vuetify.breakpoint.xsOnly" class="barcode mt-2 mb-2">
                  <span v-if="currentBusiness.metadata.barcodeOnInvoice">*{{ Invoice.localId }}*</span>
                </div>
                <div class="font-weight-regular">
                  <v-layout row>
                    <v-flex>
                      <div class="text-right">INVOICE #</div>
                      <div class="text-right">DATE:</div>
                      <div class="text-right">SALES REP:</div>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex shrink>
                      <div class="text-left">{{ Invoice.localId }}</div>
                      <div class="text-left">
                        {{ Invoice.date | moment("MM/DD/YYYY") }}
                      </div>
                      <div class="text-left" v-if="Invoice.SalesRep">
                        {{ Invoice.SalesRep.name }}
                      </div>
                      <div class="text-left" v-else>
                        {{ Invoice.User.name }}
                      </div>
                    </v-flex>
                  </v-layout>
                  <dir v-if="!currentBusiness.metadata.QRCodeOnInvoice" class="text-center">
                    <canvas id="qrcode"></canvas>
                    <div class="text-center">Order Again!</div>
                  </dir>

                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text class="pb-0" v-if="Invoice.Customer.showBalance">
            <v-layout row wrap>
              <v-spacer></v-spacer>
              <v-flex shrink>
                <div class="headline font-weight-medium text-right blue--text">
                  BALANCE
                </div>
                <div class="display-2 font-weight-light text-right">
                  {{ Invoice.Customer.balance | currency }}
                </div>
                <br />
              </v-flex>
            </v-layout>
          </v-card-text>
          <table class="center">
            <!-- <v-divider class="mx-3"></v-divider> -->

            <tbody class="report-container">
              <v-card-text>

                <v-data-table v-if="!$route.meta.public" dense sort-by="id" :headers="headers"
                  :itemsPerPage="Invoice.InvoiceDetails.length" hide-default-footer :items="GroupInvoiceDetail"
                  item-key="name">
                  <template v-slot:item="{ item, isExpanded, expand }">
                    <tr>
                      <td class="text-center">
                        {{ item.totalQty }}</td>
                      <td class="text-left pointer" v-if="item.Product.trackSerialNumber">
                        <div v-if="!isExpanded" @click="expand(true)">{{ item.name }}
                          <span v-if="item.Product && item.Product.upc"> - {{ item.Product.upc }}</span>
                          <v-icon class="no-print">mdi-chevron-down</v-icon>
                        </div>
                        <div v-if="isExpanded" @click="expand(false)">{{ item.name }}
                          <v-icon class="no-print">mdi-chevron-up</v-icon>
                        </div>
                        <div class="caption"
                          v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.printSerialNumberOnInvoice">
                          <span class="font-weight-bold ">Serial #</span>
                          <span v-for="(detail, index) in item.detail" :key="index">
                            {{ detail.imeiNumber }}
                            <span v-if="index < item.detail.length - 1" class="mr-1">,</span>
                          </span>
                        </div>
                      </td>
                      <td v-else>{{ item.name }} <span
                          v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">(≈{{
                            parseFloat(item.price) / item.Product.unitCountInBox | currency }}ea)</span>
                        <span v-if="item.Product && item.Product.upc"> - {{ item.Product.upc }}</span>
                      </td>


                      <!-- <td class="text-center"
                        v-if="currentBusiness && currentBusiness.metadata && !currentBusiness.metadata.boxCountOnInventory">
                        {{ item.totalQty }}</td> -->
                      <td class="text-right">
                        <span><span
                            v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">{{
                              item.price | currency }}</span>
                          <span v-else>{{ item.price | currency }}</span>
                          <span class="no-print" v-if="showCost">/{{ item.avgCost | currency }} - <span
                              class="red--text font-weight-bold"
                              :class="{ 'green--text': (item.price - item.avgCost > 0 ? true : false) }">{{
                                item.totalProfit | currency
                              }}</span></span>
                          <!-- <span v-if="item.Product && item.Product.unitCountInBox">/{{ item.price / item.Product.unitCountInBox | currency }}</span> -->
                        </span>
                      </td>
                      <!-- <td class="text-right" v-if="item.Product && item.Product.unitCountInBox">{{ item.price /
                        item.Product.unitCountInBox | currency }}</td> -->
                      <!-- <td class="text-right">
                        {{ item.boxPrice | currency }}
                      </td> -->
                      <td class="text-right">
                        {{ item.total | currency }}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-0 no-print">
                      <v-card flat dark>
                        <v-card-text>
                          <v-data-table dense sort-by="id" :itemsPerPage="Invoice.InvoiceDetails.length"
                            hide-default-footer :items="item.detail">
                            <template v-slot:item="{ item }">
                              <tr>
                                <!-- <td class="text-left">{{ item.name }}</td> -->
                                <td class="text-left">{{ item.imeiNumber }}</td>
                                <td class="text-center">{{ item.qty }}</td>
                                <td class="text-right">
                                  <span>{{ item.price | currency }}
                                    <span class="no-print" v-if="showCost">/{{ item.cost | currency }} - <span
                                        class="red--text font-weight-bold"
                                        :class="{ 'green--text': (item.price - item.cost > 0 ? true : false) }">{{
                                          item.price - item.cost |
                                          currency
                                        }}</span></span>
                                  </span>
                                </td>
                                <td class="text-right">
                                  <span>{{ item.boxPrice | currency }}
                                  </span>
                                </td>
                                <td class="text-right">
                                  {{ item.price * item.qty | currency }}
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                    </td>
                  </template>
                </v-data-table>

                <v-data-table v-else dense sort-by="id" :headers="headers" :itemsPerPage="Invoice.InvoiceDetails.length"
                  hide-default-footer :items="Invoice.InvoiceDetails">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-center">{{ item.totalQty }}</td>
                      <td class="text-left">{{ item.name }} <span>(≈{{ item.price | currency }}ea)</span></td>
                      <!-- <td class="text-center" >
                        {{ item.totalQty }}</td> -->
                      <td class="text-right">
                        <span>{{ item.price | currency }}
                          <span v-if="showCost">/{{ item.cost | currency }}</span>
                        </span>
                      </td>
                      <td class="text-right">
                        {{ (item.price * item.totalQty) | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
          <v-card-text class="pr-4">
            <v-divider></v-divider>
            <v-row>
              <v-col cols="4">
                <span v-if="!$route.meta.public" @click="showCost = !showCost" class="pointer font-weight-medium">
                  {{ totalQty }} <span
                    v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">BOXES</span><span
                    v-else>ITEMS</span> SOLD
                </span>
                <span v-else class="font-weight-medium">
                  {{ totalQty }} <span
                    v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">BOXES</span><span
                    v-else>ITEMS</span> SOLD
                </span>
                <div v-if="showCost" class="no-print">
                  <div>
                    <!-- <span>{{ serialQtySold }} Serial Item Sold</span>
                    <div>Sale: {{ serialSale | currency}}</div>
                    <div>Cost: {{ serialCost | currency}}</div> -->
                    <!-- <v-divider></v-divider> -->
                    <div v-if="serialProfit > 0" class="green--text">{{ serialQtySold }} - Serial Profit: <span
                        class="font-weight-bold">{{ serialProfit | currency }}</span></div>
                    <div v-else class="red--text">{{ serialQtySold }} - Serial Profit: <span class="font-weight-bold">{{
                      serialProfit | currency }}</span></div>

                    <div v-if="itemProfit > 0" class="green--text">{{ itemQtySold }} - Itemize Profit: <span
                        class="font-weight-bold">{{ itemProfit | currency }}</span></div>
                    <div v-else class="red--text">{{ itemQtySold }} - Itemize Profit: <span class="font-weight-bold">{{
                      itemProfit | currency }}</span></div>
                  </div>
                </div>
                <!-- <div class="font-weight-medium"
                  v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">
                  {{ totalBoxes }} <span v-if="totalBoxes > 1">BOXES</span><span v-else>Box</span> SOLD
                </div> -->


                <div class="signature">
                  <v-divider></v-divider>
                  <div>Signature</div>
                </div>
              </v-col>

              <v-spacer></v-spacer>
              <v-col cols="5">
                <v-layout>
                  <v-flex>
                    <div class="font-weight-medium">SUBTOTAL:</div>
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-medium text-right">
                      {{ Invoice.total | currency }}
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout v-for="(payment, index) in Invoice.PaymentDetails" :key="index">
                  <!-- <v-flex >
                    <div class="text-right" v-if="currentBusiness.metadata && currentBusiness.metadata.hidePaymentDate">
                      {{ payment.createdAt | moment("MM/DD/YYYY") }}
                    </div>
                  </v-flex> -->
                  <v-flex>
                    <div class="text-right" v-if="
                      currentBusiness.metadata &&
                      currentBusiness.metadata.hidePaymentDate
                    ">
                      {{ payment.createdAt | moment("LL") }}
                      <div class="caption"
                        v-if="currentBusiness.metadata && currentBusiness.metadata.showPaymentNoteOnInvoice && payment.Payment.note">
                        {{ payment.Payment.note }}</div>
                    </div>
                  </v-flex>
                  <v-flex xs5>
                    <div class="red--text text-right printingColor">
                      -{{ payment.amount | currency }}
                    </div>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <v-layout>
                  <v-flex>
                    <div class="font-weight-medium">TOTAL PAID:</div>
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-medium red--text text-right printingColor">
                      -{{ totalPayment | currency }}
                    </div>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <v-layout>
                  <v-flex>
                    <div class="font-weight-medium">INVOICE DUE AMOUNT:</div>
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-medium text-right">
                      {{ Invoice.total - totalPayment | currency }}
                    </div>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>

            <div class="my-5" v-if="currentBusiness &&
              currentBusiness.metadata &&
              currentBusiness.metadata.openInvoiceListOnInvoice
            ">
              <div v-if="OpenInvoices.length">
                <div class="title">Open Invoices</div>
                <OpenInvoiceList v-if="OpenInvoices" class="mt-3" :Invoice="OpenInvoices" />
              </div>
            </div>


            <div v-if="Invoice.note">
              <div class="font-weight-medium">NOTE</div>
              <v-divider></v-divider>
              <div style="line-height: 12px">
                <small>{{ Invoice.note }}</small>
              </div>
            </div>



            <!-- <v-layout row v-if="!currentBusiness.metadata.QRCodeOnInvoice">
              <v-flex shrink class="pl-0">
                <canvas id="qrcode"></canvas>
                <div class="text-center">Order Again!</div>
              </v-flex>
              <v-flex xs9>
                <div v-if="currentBusiness.policy">
                  <div class="font-weight-medium">POLICY</div>
                  <v-divider></v-divider>
                  <div style="line-height: 12px">
                    <small>{{ currentBusiness.policy }}</small>
                  </div>
                </div>
              </v-flex>

            </v-layout>

            <div v-else>
              <div v-if="currentBusiness.policy">
                <div class="font-weight-medium">POLICY</div>
                <v-divider></v-divider>
                <div style="line-height: 12px">
                  <small>{{ currentBusiness.policy }}</small>
                </div>
              </div>
            </div> -->
            <div v-if="currentBusiness.policy">
              <div class="font-weight-medium">POLICY</div>
              <v-divider></v-divider>
              <div style="line-height: 12px">
                <small>{{ currentBusiness.policy }}</small>
              </div>
            </div>


            <v-col align="right">
              <img width="100" src="@/assets/images/poweredbylogo.png" />
            </v-col>
          </v-card-text>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import PrintViewBar from "@/components/PrintViewBar";
import LinkExpiredPage from "./LinkExpired.vue";
import OpenInvoiceList from "@/modules/Invoice/components/OpenInvoiceList.vue";
import invoiceService from "@/modules/Invoice/service.js";
import Hashids from "hashids";
import QRCode from "qrcode";
import { mapGetters, mapActions } from "vuex";
const hashids = new Hashids();
import _ from 'lodash';

export default {
  data() {
    return {
      qrSize: 125,
      websiteURL: "https://www.smplpos.com",
      itemSale: 0,
      itemCost: 0,
      itemQtySold: 0,
      serialQtySold: 0,
      serialSale: 0,
      serialCost: 0,
      totalPayment: 0,
      OpenInvoices: [],
      showCost: false,
      showEmailBtn: false,
      isLoading: true,
      linkExpired: false,
      Invoice: {
        InvoiceDetails: [],
      },
      headers: [],
      GroupInvoiceDetail: [],
      totalQty: 0,
      totalBoxes: 0
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    serialProfit() {
      return this.serialSale - this.serialCost
    },
    itemProfit() {
      return this.itemSale - this.itemCost
    }
  },
  async created() {



    if (this.$route.meta.public) {
      await this.loadPublicData();
    } else {
      await this.loadData();
      if (!this.currentBusiness) {
        await this.initUser();
      }
    }
    // console.log("currentBusiness", this.currentBusiness.metadata.boxCountOnInventory);

    if (this.currentBusiness?.metadata?.boxCountOnInventory) {
      this.headers.push(
        {
          text: "BOXES",
          align: "center",
          value: "boxQty",
          width: 50,
          sortable: false,
        }
      )
    } else {
      this.headers.push({
        text: 'QTY',
        align: "center",
        value: "qty",
        width: 50,
        sortable: false,
      }
      )
    }

    this.headers.push(
      {
        text: "ITEM NAME",
        align: "left",
        value: "productDetail",
        sortable: false,
      },
    )



    // if (!this.currentBusiness?.metadata?.boxCountOnInventory) {
    //   this.headers.push({
    //     text: 'QTY',
    //     align: "center",
    //     value: "qty",
    //     width: 50,
    //     sortable: false,
    //   }
    //   )
    // }

    this.headers.push(
      {
        text: "PRICE",
        align: "right",
        value: "rate",
        width: 100,
        sortable: false,
      }

    )


    // if (this.currentBusiness?.metadata?.boxCountOnInventory) {
    //   this.headers.push(
    //     {
    //       text: "BOX PRICE",
    //       align: "center",
    //       value: "boxQty",
    //       width: 100,
    //       sortable: false,
    //     }
    //   )
    // }


    this.headers.push(
      {
        text: "TOTAL",
        align: "right",
        value: "total",
        width: 100,
        sortable: false,
      }

    )

    this.generateQRCode()
  },
  components: {
    PrintViewBar,
    OpenInvoiceList,
    LinkExpiredPage,
  },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    createToken() {
      // const hashId = hashids.encode(this.id);
      const busCode = hashids.encode(this.currentBusiness.code);
      // const productId = hashids.encode(this.productId);
      // console.log(
      //   "creating token",
      //   `http://app.smplsale/o/${busCode}-${hashId}`
      // );
      // if (this.globalLink) {

      this.websiteURL = `${process.env.VUE_APP_FE_URL}/public/order/${busCode}`;
      // if (this.productId) {
      //   this.websiteURL = `${process.env.VUE_APP_FE_URL}/public/order/${busCode}-${productId}`;
      // } else {
      //   this.websiteURL = `${process.env.VUE_APP_FE_URL}/public/order/${busCode}`;
      // }
      // } else {
      //   this.websiteURL = `${process.env.VUE_APP_FE_URL}/o/${busCode}-${hashId}`;
      // }

    },
    generateQRCode() {
      this.createToken()
      const canvas = document.getElementById("qrcode");
      canvas.width = this.qrSize;
      canvas.height = this.qrSize;
      QRCode.toCanvas(
        canvas,
        this.websiteURL,
        {
          width: this.qrSize, // Specify the QR code size
        },
        (error) => {
          if (error) {
            console.error("QR Code generation error:", error);
          }
        }
      );
      // QRCode.toCanvas(canvas, this.websiteURL, (error) => {
      //   if (error) {
      //     console.error("QR Code generation error:", error);
      //   }
      // });
    },
    loadPublicData() {
      const id = hashids.decode(this.$route.params.id);
      const key = this.$route.query.key;
      return invoiceService
        .publicGetById(id, { invoice: true, key: key })
        .then((response) => {
          // console.log("response invoice", response);
          this.initBusiness(response.business);
          this.$Progress.finish();
          this.Invoice = response.data;
          document.title = `${this.currentBusiness.name} - Invoice # ${this.Invoice.localId}`;
          if (this.Invoice.InvoiceDetails) {
            this.Invoice.InvoiceDetails.map((row) => {
              this.totalQty += Math.abs(parseInt(row.totalQty));
              this.totalBoxes += Math.abs(parseInt(row.boxQty));
            });
          }

          if (this.currentBusiness &&
            this.currentBusiness.metadata &&
            this.currentBusiness.metadata.openInvoiceListOnInvoice
          ) {
            this.getOpenInvoiceList(this.Invoice.CustomerId);
          }
          if (this.currentBusiness.metadata.invoiceLimit)
            this.linkExpired = this.$moment(this.currentBusiness.metadata.invoiceLimit).isAfter(this.Invoice.date)
          this.isLoading = false;
        });
    },
    getOpenInvoiceList(customerID) {
      // console.log('customerID', customerID)
      const where = {
        CustomerId: customerID,
        due: true,
      };
      // console.log("where", where);
      return invoiceService.getAll(where).then((response) => {
        // console.log("OpenInvoices", response);
        this.OpenInvoices = response.data;
        return response;
      });
    },
    loadData() {
      const id = hashids.decode(this.$route.params.id);
      console.log('invoice id', id[0])
      // return invoiceService.getById(id, { invoice: true }).then((response) => {
      return invoiceService.getById(id[0]).then((response) => {
        console.log("response", response);
        this.Invoice = response.data;
        if (this.Invoice?.Customer?.email) {
          this.showEmailBtn = true;
        }
        if (this.Invoice.InvoiceDetails) {
          const GroupInvoiceDetail = _.mapValues(
            _.groupBy(this.Invoice.InvoiceDetails, (detail) => {

              return detail.name + detail.price + detail.qty
            }),
            list => list,
          );
          console.log('GroupInvoiceDetail', GroupInvoiceDetail)
          // console.log('GroupInvoiceDetail', GroupInvoiceDetail)
          // let invoiceTotal = 0
          for (const property in GroupInvoiceDetail) {
            // console.log(`${property}: ${this.GroupInvoice[property]}`);
            let total = 0
            let totalQty = 0
            let boxPrice = 0
            let totalBoxes = 0
            let totalCost = 0
            let totalProfit = 0
            GroupInvoiceDetail[property].map(row => {
              // console.log('gdkfj row', property)
              totalQty += row.qty
              totalBoxes += row.boxQty
              total += row.qty * row.price
              boxPrice += total / totalBoxes
              totalCost += row.qty * row.cost
              totalProfit += row.profit
              this.totalQty += row.qty
              this.totalBoxes += row.boxQty
            })
            // console.log('GroupInvoiceDetail[property]', GroupInvoiceDetail[property])
            this.GroupInvoiceDetail.push({
              // customerId: GroupInvoiceDetail[property][0].CustomerId,
              name: GroupInvoiceDetail[property][0].name,
              price: GroupInvoiceDetail[property][0].price,
              totalQty: totalQty,
              totalBoxes: totalBoxes,
              boxPrice: boxPrice,
              total: total,
              totalProfit: totalProfit,
              avgCost: totalCost / totalQty,
              detail: GroupInvoiceDetail[property],
              Product: GroupInvoiceDetail[property][0].Product
              // value: GroupInvoice[property]
            })

            // console.log('this.GroupInvoiceDetail', this.GroupInvoiceDetail)
          }

          this.GroupInvoiceDetail.map(row => {
            // console.log('row in the row ', row)
            if (row.Product && row.Product.trackSerialNumber) {
              this.serialQtySold += parseInt(row.totalQty)
              this.serialSale += parseInt(row.totalQty) * parseFloat(row.price)
              this.serialCost += parseInt(row.totalQty) * parseFloat(row.avgCost)
            } else if (row.Product && row.Product.trackInventory && row.cost !== 0) {
              this.itemQtySold += parseInt(row.totalQty)
              this.itemSale += parseInt(row.totalQty) * parseFloat(row.price)
              this.itemCost += parseInt(row.totalQty) * parseFloat(row.avgCost)
            }
          })


          // console.log('this.GroupInvoiceDetail', this.GroupInvoiceDetail)
          // this.Invoice.InvoiceDetails.map((row) => {
          //   console.log('row', row)
          //   this.totalQty += Math.abs(parseInt(row.totalQty));
          //   if (row.Product && row.Product.trackSerialNumber) {
          //     this.serialQtySold += parseInt(row.totalQty)
          //     this.serialSale += parseInt(row.totalQty) * parseFloat(row.price)
          //     this.serialCost += parseInt(row.totalQty) * parseFloat(row.cost)
          //   } else if (row.Product && row.Product.trackInventory && row.cost !== 0) {
          //     this.itemQtySold += parseInt(row.totalQty)
          //     this.itemSale += parseInt(row.totalQty) * parseFloat(row.price)
          //     this.itemCost += parseInt(row.totalQty) * parseFloat(row.cost)
          //   }
          // });
        }

        if (this.Invoice.PaymentDetails) {
          this.Invoice.PaymentDetails.map((row) => {
            this.totalPayment += parseFloat(row.amount)
          });
        }


        if (this.currentBusiness?.metadata?.openInvoiceListOnInvoice) {
          this.getOpenInvoiceList(this.Invoice.CustomerId);
        }
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  /* canvas {
    margin: 5px auto;
    display: block;
    border: 1px solid #ccc;
    padding: 10px;
  } */

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

@media print {
  .printingColor {
    color: black !important;
  }
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
