import backofficeService from '../../api/backoffice'
import publicService from '../../api/public'
// let event = new Event('invoiceCreated')

export default {
  /**
   * Get list of products
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to products
   */
  getAll(params) {
    return backofficeService.Api().get('invoice', {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  findLostIMEINumber(params) {
    return backofficeService.Api().get('invoiceDetail/lostIMEINumber', {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  invoiceBySalesRep(params) {
    return backofficeService.Api().get('invoice/bySalesRep', {params})
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  invoiceByCustomer(params) {
    return backofficeService.Api().get('invoice/byCustomers', {params})
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  getAllInvoiceDetail(params) {
    return backofficeService.Api().get('invoiceDetail', {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  getStatement(id, params) {
    return backofficeService.Api().get(`invoice/customer/${id}`, {
        params
      })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  getPublicStatement(id, params) {
    return publicService.Api().get(`/public/invoice/customer/${id}`, {
        params
      })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  /**
   * Get specific product
   * @param {*} id
   * @param {*} params
   * @param {*} params.local Add LocalData to product
   */
  getById(id, params = {}) {
    // // console.log('getby id', id)
    return backofficeService.Api().get(`invoice/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  getByIdDeleted(id, params = {}) {
    // // console.log('getby id', id)
    return backofficeService.Api().get(`invoice/deleted/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  publicGetById(id, params = {}) {
    return publicService.Api().get(`/public/invoice/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  last(params = {}) {
    return backofficeService.Api().get(`invoiceDetail/last`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  /**
   * Create a product. Will also initiate location data
   * @param {*} data
   */
  create(data) {
    return backofficeService.Api().post('invoice', data)
      .then(response => {
        
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  migration(data) {
    return backofficeService.Api().post('migration/migrateSerialNumber', data)
      .then(response => {
        
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
  /**
   * Update specific product
   * @param {*} id
   * @param {*} data
   */
  update(id, data) {
    return backofficeService.Api().put(`invoice/${id}`, data)
      .then(response => {
        
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  adjustCost(id, data) {
    return backofficeService.Api().put(`invoice/adjustCost/${id}`, data)
    .then(response => {
      
      return response.data
    })
    .catch((error) => {
      throw error
    })
  },
  /**
   * Delete product
   * @param {} id
   * @param {*} params
   */
  delete(id, params) {
    return backofficeService.Api().delete(`invoice/${id}`, {
        params
      })
      .then(response => {
        
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

}