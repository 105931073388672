<template>
  <v-toolbar class="no-print" dense flat color="grey lighten-3">
    <v-app-bar-nav-icon @click="$router.go(-1)">
      <v-icon>mdi-chevron-left</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title v-if="!$vuetify.breakpoint.xsOnly">{{
      title
    }}</v-toolbar-title>
    <v-spacer> </v-spacer>
    <v-row align="center" justify="end">
      <v-col class="shrink">
        <v-btn
          rounded
          small
          text
          :fab="$vuetify.breakpoint.xsOnly"
          @click="print()"
          color="blue-grey"
          dark
        >
          <span v-if="!$vuetify.breakpoint.xsOnly">Print</span>
          <v-icon small :right="!$vuetify.breakpoint.xsOnly" dark
            >mdi-printer</v-icon
          >
        </v-btn>
      </v-col>
      <v-col class="shrink">
        <Email
          v-if="showEmailBtn && !$route.meta.public"
          :id="id"
          :type="type"
          :postfix="postfix"
          :name="name"
          :email="email"
          :obj="obj"
        />
      </v-col>
      <v-col class="shrink">
        <ShareLink
          v-if="showEmailBtn && !$route.meta.public"
          :id="id"
          :button="true"
          :type="type"
          :postfix="postfix"
          :text="text"
        />
      </v-col>
    </v-row>
  </v-toolbar>
</template>

<script>
import Email from "@/components/EmailOrders";
import ShareLink from "@/components/ShareLink";
export default {
  props: [
    "email",
    "type",
    "id",
    "name",
    "postfix",
    "obj",
    "text",
    "title",
    "showEmailBtn",
  ],
  name: "print-view-bar",
  components: {
    Email,
    ShareLink,
  },
  created() {
    document.title = `smplsale | ${this.title}`
  },
  methods: {
    printDirectly() {
      axios.post('http://localhost:3000/print', {
        text: 'Hello, this is a test print.'
      })
      .then(response => {
        console.log('Print job sent successfully');
      })
      .catch(error => {
        console.error('Error:', error);
      });
    },
    print() {
      window.print();
    },
  },
};
</script>

<style scoped></style>
