<template>
  <div>
    <table class="center">
      <tbody class="report-container">
        <v-card-text class="pt-0">
          <v-data-table
            dense
            :headers="headers"
            :itemsPerPage="Invoice.length"
            hide-default-footer
            :items="Invoice"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left">
                  {{ item.date | moment("MM/DD/YYYY") }}
                </td>
                <td class="text-center">
                  <a href="#" @click="viewInvoice(item)">{{ item.localId }}</a>
                </td>
                <td class="text-right">
                  {{ item.total | currency }}
                </td>
                <td class="text-right">
                  {{ item.due | currency }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </tbody>
    </table>
  </div>
</template>

<script>

import Hashids from "hashids";
const hashids = new Hashids();

export default {
  name: "open-invoice-list",
  props:['Invoice'],
  data() {
    return {
      headers: [
        {
          text: "DATE",
          align: "left",
          value: "date",
          sortable: false,
        },
        {
          text: "INVOICE #",
          align: "center",
          value: "localId",
          sortable: false,
        },
        {
          text: "AMOUNT",
          align: "right",
          value: "total",
          sortable: false,
        },
        {
          text: "BALANCE",
          align: "right",
          value: "due",
          sortable: false,
        },
      ],
      totalQty: 0,
    };
  },
  methods: {
    viewInvoice(invoice) {
      const hashId = hashids.encode(invoice.id);
      let routeData = null;
      if (this.$route.meta.public) {
        routeData = this.$router.resolve({
          path: `/public/invoice/${hashId}/view`,
        });
      } else {
        routeData = this.$router.resolve({
          path: `/global/invoice/${hashId}/view`,
        });
      }

      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    print() {
      window.print();
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
