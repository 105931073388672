<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="button"
        rounded
        text
        small
        :fab="$vuetify.breakpoint.xsOnly"
        v-on="on"
        color="blue-grey"
        dark
      >
        <span v-if="!$vuetify.breakpoint.xsOnly">Share</span>
        <v-icon :right="!$vuetify.breakpoint.xsOnly" small dark
          >mdi-share</v-icon
        >
      </v-btn>
      <v-list-item v-on="on" v-else>
        <v-list-item-title>View Share Link</v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <ModelTitle title="Share Link" @close="dialog = false" />
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-card dark flat color="text--white">
              <v-card-title>
                <span>Copy This URL To Share</span>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small @click="copyLink" fab v-on="on">
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>Copy link</span>
                </v-tooltip>
                <v-tooltip bottom v-if="showShare">
                  <template v-slot:activator="{ on }">
                    <v-btn small @click="shareLink" fab v-on="on">
                      <v-icon>mdi-share</v-icon>
                    </v-btn>
                  </template>
                  <span>Copy link</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text>
                <span style="color: white">{{ text }} {{ url }} </span>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <whats-app
                  :url="copyValue"
                  title="smplsale link"
                  scale="2"
                ></whats-app>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!-- <div class="title">Copy This URL To Share</div>
        <div class="mt-3">{{ url }}</div>
        <v-btn rounded @click="copyLink" color="info">Copy</v-btn> -->
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import copy from "copy-to-clipboard";
import Hashids from "hashids";
import { WhatsApp } from 'vue-socialmedia-share';
import { mapGetters } from "vuex";
const hashids = new Hashids();

export default {
  props: ["type", "id", "postfix", "obj", "text", "button"],
  data() {
    return {
      showShare: false,
      url: "Creating URL Please wait...",
      dialog: null,
    };
  },
  components: {
    WhatsApp,
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    copyValue() {
      return `${this.text} ${this.url}`
    }
  },
  watch: {
    dialog(val) {
      if (val) {
        const hashId = hashids.encode(this.id);
        // this.url = btoa("category=textile&user=user1")
        // this.urlc = atob(this.url)
        this.url = `${process.env.VUE_APP_FE_URL}/public/${this.type}/${hashId}/${this.postfix}`;
      }
    },
  },
  created() {
    if (navigator.share) {
      this.showShare = true;
    } else {
      this.showShare = false;
    }
  },
  methods: {
    shareLink() {
      if (navigator.share) {
        let copyValue = `${this.text} ${this.url}`
        navigator
          .share({
            title: this.currentBusiness.name,
            text: this.text,
            url: this.url,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing:", error))
      }
      this.dialog = false;
    },
    copyLink() {
      let copyValue = `${this.text} ${this.url}`
      copy(copyValue);
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        })
        .fire({
          icon: "success",
          title: "Link is copy!",
        });

      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
