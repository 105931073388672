import backofficeService from '../../api/backoffice'
// let cacheCustomer = null
// let cacheTopCustomer = null
// let cacheOutstandingBalance = null

// setInterval(() => {
//   nullEverything()
// }, 3600000);

// function nullEverything() {
//   // // console.log('i was nullEverything here')
//   cacheCustomer = null
//   cacheOutstandingBalance = null
//   cacheTopCustomer = null
// }

// window.onload = function () {

//   document.addEventListener('invoiceCreated', nullEverything)
// }
// document.addEventListener('invoiceCreated', nullEverything)


export default {
  /**
   * Get list of categories
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.assignedToUserID
   * @param {*} params.assignedToMe
   * @param {*} params.reporterUserID
   * @param {*} params.reporteredByMe
   */
  async getAll(params) {
    return backofficeService.Api().get('customer', {
        params
      })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  async noInvoice(params) {
    return backofficeService.Api().get('report/noInvoice/customer', {
        params
      })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  async getAllCount(params) {
    return backofficeService.Api().get('customer', {
        params
      })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  async sendEmail(params) {
    return backofficeService.Api().post('/email/send', params)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },


  /**
   * Update specific category
   * @param {*} id
   * @param {*} data
   */
  update(id, data) {
    return backofficeService.Api().put(`customer/${id}`, data)
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  /**
   * Update specific category
   * @param {*} id
   * @param {*} data
   */
  syncBalance(id) {
    return backofficeService.Api().get(`customer/syncBalance/${id}`)
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  create(data) {
    return backofficeService.Api().post('customer', data)
      .then(response => {
        return response.data
      })
      .catch(error => error)
  },

  /**
   * Get specific product
   * @param {*} id
   * @param {*} params
   * @param {*} params.local Add LocalData to product
   */
  getById(id, params = {}) {
    return backofficeService.Api().get(`customer/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },


  async getTopCustomer(id, params = {}) {
    // if (cacheTopCustomer) return cacheTopCustomer
    return backofficeService.Api().get(`customer/topCustomer`, {
        params
      })
      .then(response => {
        // cacheTopCustomer = response.data
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  /**
   * Get outstanding balance
   * @param {*} id
   * @param {*} params
   * @param {*} params.local Add LocalData to product
   */
  async getOutstandingBalance(id, params = {}) {
    // if (cacheOutstandingBalance) return cacheOutstandingBalance
    return backofficeService.Api().get(`customer/outstandingBalance`, {
        params
      })
      .then(response => {
        // cacheOutstandingBalance = response.data
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },


  // getByTask(taskID, params) {
  //   return backofficeService.Api().get(`api/comment/byTask/${taskID}`, { params })
  //     .then(response => response.data)
  //     .catch((error) => { throw error })
  // },
  delete(id) {
    return backofficeService.Api().delete(`customer/${id}`)
      .then(response => {
        // nullEverything()
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
  recover(id) {
    return backofficeService.Api().get(`customer/recover/${id}`)
      .then(response => {
        // nullEverything()
        return response.data
      })
      .catch((error) => {
        throw error
      })
  }
}